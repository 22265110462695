import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { NamedSeries } from '../api/models/named-series'
import { Metrics } from '../api/models/metrics'
import { Editor } from '../api/models/editor'
import moment from 'moment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { switchMap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin, from } from 'rxjs';
import { Type } from '../api/models/type';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { BlockLike } from 'typescript';
import { bool } from 'aws-sdk/clients/signer';


export interface MetricGroup {
  index: number,
  name: string,
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-audiomatch-metrics',
  templateUrl: './audiomatch-metrics.component.html',
  styleUrls: ['./audiomatch-metrics.component.css']
})
export class AudiomatchMetricsComponent implements OnInit, AfterViewInit {

  fingerprintVersions = environment.fingerprintVersions;
  defaultFingerprintVersion = environment.defaultFingerprintVersion;

  colours = {
    "1": "#FFBF00", "2": "#FF7F50", "3": "#DE3163", "4": "#9FE2BF",
    "5": "#40E0D0", "6": "#6495ED", "7": "#DFFF00", "8": "#CCCCFF",
    "9": "#FFBF00", "10": "#FF7F50", "11": "#DE3163", "12": "#9FE2BF",
    "13": "#40E0D0", "14": "#6495ED", "15": "#DFFF00", "16": "#CCCCFF",
    "17": "#FFBF00", "18": "#FF7F50", "19": "#DE3163", "20": "#9FE2BF",
    "21": "#40E0D0", "22": "#6495ED", "23": "#DFFF00", "24": "#CCCCFF",
    "25": "#FFBF00", "26": "#FF7F50", "27": "#DE3163", "28": "#9FE2BF",
    "29": "#40E0D0", "30": "#6495ED", "31": "#DFFF00", "32": "#CCCCFF",
  }

  ranges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  editors$: Observable<Editor[]>;
  channelBrandDescriptions$: Observable<String[]>;

  dataLoading: boolean = true;

  metricGroups: MetricGroup[] = [{
    index: 0,
    name: 'Contents Metrics',
  },
  {
    index: 1,
    name: 'Download Metrics'
  },
  {
    index: 2,
    name: 'Audiofeat Metrics'
  },
  {
    index: 3,
    name: 'Audiomatch Metrics'
  },
  {
    index: 4,
    name: 'Showschedule Metrics'
  },
  {
    index: 5,
    name: 'Genre Metrics'
  }];

  // Filters

  selectedGroup = new FormControl(0)

  dateRange = new FormControl({
    endDate: moment(),
    startDate: moment().subtract(6, 'days')
  });
  editorStatus = new FormControl();
  channelBrandDescriptionStatus = new FormControl();
  typeFilter = new FormControl();
  missingIntervalTh = new FormControl();
  genreLevel = new FormControl();

  // Charts

  charts = [
    {
      groupIndex: 0,
      title: 'Number of Ondemand Daily Contents',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Number of Linear Daily Contents',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Number of Adv Daily Contents',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Number of Total Contents by Editor',
      description: '',
      results: [],
      params: { name: Metrics.CountContentsTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 0,
      title: 'Ondemand Daily Contents Duration (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Linear Daily Contents Duration (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Adv Daily Contents Duration (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 0,
      title: 'Total Contents Duration by Editor (hours)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsDurationTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: 3600,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    // {
    //   groupIndex: 0,
    //   title: 'Daily Contents Size (GB)',
    //   description: '',
    //   results: [],
    //   params: { name: Metrics.ContentsSizeDailyByDate },
    //   isDateRangeDependent: true,
    //   isStatusEditorDependent: false,
    //   scaleValue: 1e9,
    //   cols: 12,
    //   rows: 1,
    //   flex: "100%",
    //   class: "chart",
    //   chart_type: 'area-chart-stacked'
    // },
    // {
    //   groupIndex: 0,
    //   title: 'Total Contents Size by Editor (TB)',
    //   description: '',
    //   results: [],
    //   params: { name: Metrics.ContentsSizeTotByEditor },
    //   isDateRangeDependent: false,
    //   isStatusEditorDependent: false,
    //   scaleValue: 1e12,
    //   cols: 12,
    //   rows: 1,
    //   flex: "100%",
    //   class: "chart",
    //   chart_type: 'advanced-pie-chart'
    // },
    {
      groupIndex: 0,
      title: 'Total Contents Size by Type (TB)',
      description: '',
      results: [],
      params: { name: Metrics.ContentsSizeTotByFiletype },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: 1e3,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 1,
      title: 'Ondemand Daily Failed Download',
      description: '',
      xAxisTicks: [],
      results: [],
      params: { name: Metrics.FailedDownloadDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 1,
      title: 'Linear Daily Failed Download',
      description: '',
      results: [],
      params: { name: Metrics.FailedDownloadDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 1,
      title: 'Adv Daily Failed Download',
      description: '',
      results: [],
      params: { name: Metrics.FailedDownloadDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 1,
      title: 'Failed Download by Editor',
      description: '',
      results: [],
      params: { name: Metrics.FailedDownloadTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 2,
      title: 'Ondemand Daily Failed Fingerprint',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 2,
      title: 'Linear Daily Failed Fingerprint',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 2,
      title: 'Adv Daily Failed Fingerprint',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 2,
      title: 'Failed Fingerprint by Editor',
      description: '',
      results: [],
      params: { name: Metrics.FailedFingerprintTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 1,
      title: 'Download Status',
      description: '',
      results: [],
      params: { name: Metrics.DownloadStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 2,
      title: 'Audiofeat Status',
      description: '',
      results: [],
      params: { name: Metrics.AudiofeatStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 3,
      title: 'Audiomatch Contents Status',
      description: '',
      results: [],
      params: { name: Metrics.AudiomatchContentsStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 3,
      title: 'Audiomatch Runs Status',
      description: '',
      results: [],
      params: { name: Metrics.AudiomatchRunsStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 1,
      title: 'Download Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.DownloadErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "hight-chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 2,
      title: 'Audiofeat Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.AudiofeatErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "hight-chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 3,
      title: 'Audiomatch Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.AudiomatchErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "hight-chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 4,
      title: 'Showschedule Status',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 4,
      title: 'Showschedule Status by Channel',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleStatusByChannel },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: false,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 4,
      title: 'Showschedule Completeness',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleCompleteness },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: false,
      isMissingIntervalThDependent: true,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 5,
      title: 'Genre Status',
      description: '',
      results: [],
      params: { name: Metrics.GenreStatus },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 5,
      title: 'Genre Error by Type',
      description: '',
      results: [],
      params: { name: Metrics.GenreErrorByType },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: true,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 5,
      title: 'Ondemand Daily Failed Genre',
      description: '',
      results: [],
      params: { name: Metrics.FailedGenreDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 5,
      title: 'Adv Daily Failed Genre',
      description: '',
      results: [],
      params: { name: Metrics.FailedGenreDailyByDate },
      isDateRangeDependent: true,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 5,
      title: 'Failed Genre by Editor',
      description: '',
      results: [],
      params: { name: Metrics.FailedGenreTotByEditor },
      isDateRangeDependent: false,
      isStatusEditorDependent: false,
      isChannelDependent: false,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'advanced-pie-chart'
    },
    {
      groupIndex: 5,
      title: 'Showschedule Genres Level 1',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleGenre, genre_level: 'level1' },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 5,
      title: 'Showschedule Genres Level 2',
      description: '',
      results: [],
      params: { name: Metrics.ShowscheduleGenre, genre_level: 'level2' },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'pie-chart'
    },
    {
      groupIndex: 5,
      title: 'Showschedule Daily Genre Confidence Level 1',
      description: '',
      results: [],
      customColors: [
        {"name": "delta_p_level_1","value": "#82a4e0"},
        {"name": "delta_p_level_1_threshold","value": "#ff0000"}
      ],
      params: { name: Metrics.ShowscheduleGenreConfidence, genre_level: 'level1' },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
    {
      groupIndex: 5,
      title: 'Showschedule Daily Genre Confidence Level 2',
      description: '',
      results: [],
      customColors: [
        {"name": "delta_p_level_2","value": "#82a4e0"},
        {"name": "delta_p_level_2_threshold","value": "#ff0000"}
      ],
      params: { name: Metrics.ShowscheduleGenreConfidence, genre_level: 'level2' },
      isDateRangeDependent: true,
      isStatusEditorDependent: true,
      isChannelDependent: true,
      isTypeDependent: false,
      isMissingIntervalThDependent: false,
      scaleValue: null,
      cols: 12,
      rows: 1,
      flex: "100%",
      class: "chart",
      chart_type: 'line-chart'
    },
  ];

  filterGroups = [];

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  getYscaleMin(metrics){
    if (metrics.title.toLowerCase().includes('confidence')){
      return 0
    }else{
      return undefined
    }
  }

  getYscaleMax(metrics){
    if (metrics.title.toLowerCase().includes('confidence')){
      return 1
    }else{
      return undefined
    }
  }

  scaleValue(data: Array<NamedSeries>, scaleFactor: number): Array<NamedSeries> {
    let temp = []
    data.forEach(
      element => {
        if ('series' in element) {
          let scaledSeries = [];
          element['series'].forEach(
            item => {
              if (item['value'] != null) {
                scaledSeries.push(
                  { 'name': item['name'], 'value': item['value'] / scaleFactor }
                )
              }
            }
          )
          temp.push(
            { 'name': element['name'], 'series': scaledSeries }
          )
        } else {
          if (element['value'] != null) {
            temp.push(
              { 'name': element['name'], 'value': element['value'] / scaleFactor }
            )
          }
        }
      }
    )
    return temp
  }

  ngOnInit(): void {

    this.initFilterGroups();

    this.editors$ = this.retrieve.getEditorsList()

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.typeFilter.setValue(params['type'] ? params['type'] : 'ondemand', { emitEvent: false });
      this.editorStatus.setValue(params['editor'], { emitEvent: false });
      this.channelBrandDescriptionStatus.setValue(params['channel'], { emitEvent: false });
      this.selectedGroup.setValue(params['group'] ? parseInt(params['group']) : 0, { emitEvent: false });
      this.dateRange.setValue({
        startDate: params['startDate'] ? moment(params['startDate']) : moment().subtract(6, 'days'),
        endDate: params['endDate'] ? moment(params['endDate']) : moment(),
      }, { emitEvent: false });
      this.missingIntervalTh.setValue(params['missingIntervalTh'] ? parseFloat(params['missingIntervalTh']) : 300, { emitEvent: false });
      this.genreLevel.setValue(params['genreLevel'], { emitEvent: false });
      if (this.editorStatus.value && !this.channelBrandDescriptionStatus.value) {
        this.updateStatusMetrics(true);
      }
      this.updateMetrics();
    });
  }

  initFilterGroups() {
    let possibleValues = [true, false]
    for (var i = 0; i < possibleValues.length; i++) {
      for (var j = 0; j < possibleValues.length; j++) {
        for (var k = 0; k < possibleValues.length; k++) {
          for (var z = 0; z < possibleValues.length; z++) {
            for (var t = 0; t < possibleValues.length; t++) {
              let filters = {
                'isDateRangeDependent': possibleValues[i],
                'isTypeDependent': possibleValues[j],
                'isStatusEditorDependent': possibleValues[k],
                'isChannelDependent': possibleValues[z],
                'isMissingIntervalThDependent': possibleValues[t],
              }
              this.filterGroups.push(filters);

            }
          }
        }
      }
    }
    console.log(this.filterGroups)
  }

  ngAfterViewInit() {
    this.dateRange.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          startDate: value.startDate,
          endDate: value.endDate
        },
        queryParamsHandling: 'merge'
      });
    });

    this.editorStatus.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          editor: value,
        },
        queryParamsHandling: 'merge'
      });
    });


    this.typeFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (value == 'ondemand') {
        this.router.navigate(['metrics/'], {
          queryParams: {
            type: value,
          },
          queryParamsHandling: 'merge'
        });
      } else {
        this.router.navigate(['metrics/'], {
          queryParams: {
            type: value,
            channel: null,
            editor: null
          },
          queryParamsHandling: 'merge'
        });
      }
    });


    this.channelBrandDescriptionStatus.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          channel: value,
        },
        queryParamsHandling: 'merge'
      });
    });


    this.selectedGroup.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          group: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.missingIntervalTh.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['metrics/'], {
        queryParams: {
          missingIntervalTh: value,
        },
        queryParamsHandling: 'merge'
      });
    });


    this.genreLevel.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {

      console.log(value)

      this.router.navigate(['metrics/'], {
        queryParams: {
          genreLevel: value,
        },
        queryParamsHandling: 'merge'
      });
    });
  }


  updateMetrics(): void {

    this.dataLoading = true;

    console.log(this.missingIntervalTh.value)

    let metricsToUpdate = []
    this.charts.forEach(
      chart => {
        if (chart.groupIndex == parseInt(this.selectedGroup.value)) {
          chart.results = [];
          if (!(chart.title.toLowerCase().includes('daily') && (chart.title.toLowerCase().includes('linear') || chart.title.toLowerCase().includes('adv')))) {
            metricsToUpdate.push(this.retrieve.getMetrics(Object.assign({}, chart.params, {
              version: this.defaultFingerprintVersion,
              dateFrom: this.dateRange.value.startDate.toISOString(),
              dateTo: this.dateRange.value.endDate.toISOString(),
              editor_status: this.editorStatus.value ? this.editorStatus.value : null,
              type: this.typeFilter.value ? this.typeFilter.value : null,
              channelbranddescription_status: this.channelBrandDescriptionStatus.value ? this.channelBrandDescriptionStatus.value : null,
              missingIntervalThreshold: this.missingIntervalTh.value ? this.missingIntervalTh.value : null,
            })))
          }
        }
      }
    )


    forkJoin(
      metricsToUpdate
    ).subscribe(
      (data: Array<NamedSeries[]>) => {
        let idx = 0;
        this.charts.forEach(
          chart => {
            if (chart.groupIndex == parseInt(this.selectedGroup.value)) {
              if (!(chart.title.toLowerCase().includes('daily') && (chart.title.toLowerCase().includes('linear') || chart.title.toLowerCase().includes('adv')))) {
                if (chart.title.toLowerCase().includes('daily')) {
                  data[idx].forEach((element: any) => {
                    element.series.forEach((item: any) => {
                      item.name = new Date(moment(item.name).toISOString());
                    });
                  });
                }

                if (chart.chart_type == 'pie-chart') {
                  data[idx].forEach((element: any) => {
                    element.name = element.name + ' (' + element.value.toFixed(2) + ' %) '
                  })
                }

                if (chart.title.toLowerCase().includes('ondemand')) {
                  let ondemand_data = []
                  let linear_data = []
                  let adv_data = []
                  if (chart.title.includes('Daily')) {
                    data[idx].forEach((element: any) => {
                      if (element.name.toLowerCase().includes('ondemand')) {
                        ondemand_data.push(element)
                      } else if (element.name.toLowerCase().includes('adv')) {
                        adv_data.push(element)
                      } else {
                        linear_data.push(element)
                      }
                    });
                  }
                  chart.results = chart.scaleValue == null ? this.scaleValue(ondemand_data, 1) : this.scaleValue(ondemand_data, chart.scaleValue);
                  const result_linear = this.charts.find(({ title }) => title.toLowerCase() === chart.title.toLowerCase().replace('ondemand', 'linear'));
                  if (result_linear) {
                    result_linear.results = chart.scaleValue == null ? this.scaleValue(linear_data, 1) : this.scaleValue(linear_data, chart.scaleValue);
                  }

                  const result_adv = this.charts.find(({ title }) => title.toLowerCase() === chart.title.toLowerCase().replace('ondemand', 'adv'));
                  if (result_adv) {
                    result_adv.results = chart.scaleValue == null ? this.scaleValue(adv_data, 1) : this.scaleValue(adv_data, chart.scaleValue);
                  }
                  // chart.xAxisTicks = []
                  // data[idx][0].series.forEach((item: any) => {
                  //     chart.xAxisTicks.push(item.name);
                  //   });

                } else {
                  chart.results = chart.scaleValue == null ? this.scaleValue(data[idx], 1) : this.scaleValue(data[idx], chart.scaleValue);
                }
                idx++;

              }
            }
          }
        )
        this.dataLoading = false;
      }
    )
  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      return (<Date>val).toLocaleString('it-IT', { year: '2-digit', month: '2-digit', day: '2-digit' });
    }
  }

  updateStatusMetrics(updateChannels: boolean = false): void {
    if (updateChannels) {
      if (this.editorStatus) {
        if (this.selectedGroup.value == 4) {
          this.channelBrandDescriptions$ = this.retrieve.getShowScheduleChannel().pipe(
            map((results) => {
              let channels: String[] = [];
              results.forEach(
                (result) => {
                  if (result.editor.toLowerCase() == this.editorStatus.value) {
                    channels.push(result.channel);
                  }
                }
              )
              return channels
            })
          )
        } else {
          this.channelBrandDescriptions$ = this.retrieve.getChannelBrandDescriptionList({
            editor: this.editorStatus.value,
            dateFrom: this.dateRange.value.startDate.toISOString(),
            dateTo: this.dateRange.value.endDate.toISOString(),
          })
        }
      } else {
        this.channelBrandDescriptions$ = of([])
      }
      this.channelBrandDescriptionStatus.setValue('');
    }
  }

  getErrorMessage(title: string): string {
    if (title.toLowerCase().includes('daily') && (title.toLowerCase().includes('failed') || title.toLowerCase().includes('error'))) {
      return "NO ERROR FOUND FOR THE SELECTED TIME INTERVAL"
    } else if (title.toLowerCase().includes('failed') || title.toLowerCase().includes('error')) {
      return "NO ERROR FOUND"
    } else if (title.toLowerCase().includes('status')) {
      return "NO CONTENT INSERTED FOR THE SELECTED TIME INTERVAL"
    } else {
      return "NO DATA FOUND"
    }
  }

  sortData(data) {
    let sortedData = data.sort((a, b) => b.value - a.value);
    return sortedData
  }

  roundValue(val) {
    return Math.round(val * 100) / 100
  }

  numberOfChart(groupIndex: number, isDateRangeDependent: boolean, isTypeDependent: boolean, isStatusEditorDependent: boolean, isChannelDependent: boolean,
    isMissingIntervalThDependent: boolean) {
    let n = 0;
    this.charts.forEach((chart) => {
      if (chart.groupIndex == groupIndex
        && chart.isDateRangeDependent == isDateRangeDependent
        && chart.isTypeDependent == isTypeDependent
        && chart.isStatusEditorDependent == isStatusEditorDependent
        && chart.isChannelDependent == isChannelDependent
        && chart.isMissingIntervalThDependent == isMissingIntervalThDependent) {
        n++;
      }
    })
    return n
  }

  chartToPlot(groupIndex, filterGroup) {
    let selectedChart = []

    this.charts.forEach((chart) => {
      if (chart.groupIndex == groupIndex
        && chart.isDateRangeDependent == filterGroup.isDateRangeDependent
        && chart.isTypeDependent == filterGroup.isTypeDependent
        && chart.isStatusEditorDependent == filterGroup.isStatusEditorDependent
        && chart.isChannelDependent == filterGroup.isChannelDependent
        && chart.isMissingIntervalThDependent == filterGroup.isMissingIntervalThDependent) {
        selectedChart.push(chart)
      }
    })

    return selectedChart
  }

  getCardTitle(filterGroup) {
    let cardTitle = ''
    if (filterGroup.isDateRangeDependent) {
      if (cardTitle) {
        cardTitle += ', Date'
      } else {
        cardTitle += 'Date'
      }
    }

    if (filterGroup.isTypeDependent) {
      if (cardTitle) {
        cardTitle += ', Type'
      } else {
        cardTitle += 'Type'
      }
    }

    if (filterGroup.isStatusEditorDependent) {
      if (cardTitle) {
        cardTitle += ', Editor'
      } else {
        cardTitle += 'Editor'
      }
    }

    if (filterGroup.isChannelDependent) {
      if (cardTitle) {
        cardTitle += ', Channel'
      } else {
        cardTitle += 'Channel'
      }
    }


    if (filterGroup.isMissingIntervalThDependent) {
      if (cardTitle) {
        cardTitle += ', Missing Interval Th'
      } else {
        cardTitle += 'Missing Interval Th'
      }
    }

    if (cardTitle) {
      cardTitle += ' dependend graphs'
    } else {
      cardTitle = 'Filter independent graphs'
    }
    return cardTitle
  }

  getHeaderColor(idx) {
    return this.colours[Object.keys(this.colours)[idx]];
  }

  export(metrics) {

    let filename = metrics.title;
    let fileType = 'csv';

    let blob
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    let header = []

    let rowFilter = []
    if (metrics.isDateRangeDependent && this.dateRange.value) {
      header = ['Date Filter From', 'Date Filter To'];
      rowFilter.push(this.dateRange.value.startDate.toISOString(true));
      rowFilter.push(this.dateRange.value.endDate.toISOString(true));
    }

    if (metrics.isTypeDependent && this.typeFilter.value) {
      header.push('Type Filter');
      rowFilter.push(this.typeFilter.value);
    }

    if (metrics.isStatusEditorDependent && this.editorStatus.value) {
      header.push('Editor Filter');
      rowFilter.push(this.editorStatus.value);
    }

    if (metrics.isChannelDependent && this.channelBrandDescriptionStatus.value) {
      header.push('Channel Filter');
      rowFilter.push(this.channelBrandDescriptionStatus.value);
    }

    let csv = []
    let csvString
    if (metrics.title.toLowerCase().includes('daily')) {
      header.push(...['Name', 'Date', 'Value']);
      csv = [header.join(';')] // header row first]

      metrics.results.forEach((element: any) => {
        element.series.forEach((item: any) => {
          csv.push([...rowFilter, element.name, moment(item.name).toISOString(true), item.value].join(';'))
        });
      });
    } else {
      header.push(...['Name', 'Value']);
      csv = [header.join(';')] // header row first]

      metrics.results.forEach((element: any) => {
        csv.push([...rowFilter, element.name, element.value].join(';'))
      });
    }
    csvString = csv.join('\r\n')
    blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });


    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }

  updateTypeDepentFilter() {
    if (this.typeFilter.value == 'adv') {
      this.editorStatus.disable();
      this.channelBrandDescriptionStatus.disable();
    } else {
      this.editorStatus.enable();
      this.channelBrandDescriptionStatus.enable();
    }
  }

  getPieChartValue(value) {
    return value.toFixed(2) + ' %'
  }


  getPieChartName(name) {
    let newName = name.split('(')
    return newName[0]
  }
}
